export enum BET_MARKET_TYPE_GROUP_ENUM {
  UNKNOWN = "UNKNOWN",
  MONEYLINE = "MONEYLINE",
  SPREAD = "SPREAD",
  TOTALS = "TOTALS",
  PLAYER_PROP = "PLAYER_PROP",
  TEAM = "TEAM",
  RACE_TO = "RACE_TO",
  GAME = "GAME",
}

export const filterMarketTypes: Record<string, string> = {
  [BET_MARKET_TYPE_GROUP_ENUM.MONEYLINE]: "MONEY_LINE",
  [BET_MARKET_TYPE_GROUP_ENUM.SPREAD]: "SPREAD",
  [BET_MARKET_TYPE_GROUP_ENUM.TEAM]: "TEAM",
  [BET_MARKET_TYPE_GROUP_ENUM.TOTALS]: "TOTAL",
  [BET_MARKET_TYPE_GROUP_ENUM.PLAYER_PROP]: "PLAYER_PROP",
  [BET_MARKET_TYPE_GROUP_ENUM.GAME]: "GAME",
};

// order of the enums
export const BET_MARKET_TYPE_GROUP_ORDER = [
  BET_MARKET_TYPE_GROUP_ENUM.MONEYLINE,
  BET_MARKET_TYPE_GROUP_ENUM.SPREAD,
  BET_MARKET_TYPE_GROUP_ENUM.TOTALS,
  BET_MARKET_TYPE_GROUP_ENUM.TEAM,
  BET_MARKET_TYPE_GROUP_ENUM.PLAYER_PROP,
  BET_MARKET_TYPE_GROUP_ENUM.GAME,
  BET_MARKET_TYPE_GROUP_ENUM.RACE_TO,
  BET_MARKET_TYPE_GROUP_ENUM.UNKNOWN,
];


// display names for the enums
export const BET_MARKET_TYPE_GROUP_DISPLAY_NAMES: Record<BET_MARKET_TYPE_GROUP_ENUM, string> = {
  [BET_MARKET_TYPE_GROUP_ENUM.UNKNOWN]: "Miscellaneous",
  [BET_MARKET_TYPE_GROUP_ENUM.MONEYLINE]: "Moneyline",
  [BET_MARKET_TYPE_GROUP_ENUM.SPREAD]: "Spread",
  [BET_MARKET_TYPE_GROUP_ENUM.TOTALS]: "Totals",
  [BET_MARKET_TYPE_GROUP_ENUM.PLAYER_PROP]: "Player Prop",
  [BET_MARKET_TYPE_GROUP_ENUM.TEAM]: "Team",
  [BET_MARKET_TYPE_GROUP_ENUM.RACE_TO]: "Race To",
  [BET_MARKET_TYPE_GROUP_ENUM.GAME]: "Game",
};