/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BET_MARKET_TYPE_GROUP_ENUM } from "@/components/Common/Enums/BetMarketTypeGroupEnum";
import { BetMarketType, IPlayerType } from "@/components/Odds/common";
import { americanToDecimal } from "@/utis/bettingFormulas";
import { BetMarketSiteBetSiteType } from "./sportsBook";
import { GameData } from "./gameDataCache";

type GraphTeamData = {
  gameId: string;
  teamId: string;
  betValue?: number;
  playerId?: number;
};

type BetCacheType = {
  betValueRange: Record<string, number[]>;
  odds: Record<string, Record<string, Record<string, Record<string, number>>>>;
  hashCode: Record<string, Record<string, number>>;
  gameData: Record<string, GameData>;
  playerData: Record<number, IPlayerType>;
  playerByGames: Record<string, number[]>;
  teamByGames: Record<string, number[]>;
  selectedBetValue: Record<string, number | undefined>;
  draws: Record<string, boolean>;
  selectedSportbooks: BetMarketSiteBetSiteType[];
  allSportbooks: BetMarketSiteBetSiteType[];
  searchQuery: string;
  isGraphOpen: boolean;
  graphTeamData?: GraphTeamData;
};

type SetSpreadParams = {
  gameId: string;
  betValue: number;
};

const initialState: BetCacheType = {
  odds: {},
  hashCode: {},
  betValueRange: {},
  gameData: {},
  playerData: {},
  playerByGames: {},
  teamByGames: {},
  selectedBetValue: {},
  draws: {},
  selectedSportbooks: [],
  allSportbooks: [],
  searchQuery: "",
  isGraphOpen: false,
};

export const betCache = createSlice({
  name: "constants",
  initialState,
  reducers: {
    setOdds: (
      state,
      action: PayloadAction<{ marketTypes: BetMarketType[]; betGroup?: BET_MARKET_TYPE_GROUP_ENUM; marketType?: string }>,
    ) => {
      const { marketTypes, betGroup, marketType } = action.payload;
      const sums: Record<string, Record<number, number>> = {};
      const counts: Record<string, Record<number, number>> = {};
      const isPlayerView = betGroup?.startsWith("PLAYER_PROP");
      const shouldInvert = betGroup?.startsWith("TOTALS") || isPlayerView || betGroup?.includes("TEAM");

      // Don't update data when Graph is open
      if (state.isGraphOpen) return;

      // re calculating odds everytime, should we do it or not?
      // To remove the data when odds is missing or null
      state.odds = {};

      marketTypes.forEach(({ gameId, conditions, listings, hashCode }) => {
        if (conditions.length < 1) return;
        const condition = conditions[0];
        // Check if the query marketType is same as the returned market type
        if (marketType && condition.marketType !== marketType) return;
        // start adding bet value range
        if (isPlayerView && !condition.playerId) {
          return;
        }
        let betKey = isPlayerView ? condition.playerId.toString() : gameId;
        const { betValue } = condition;

        let teamKey = `${condition.teamId ?? ""}`;
        teamKey += condition.overUnder ?? "";
        if (condition.teamId && condition.overUnder) {
          betKey = `${condition.teamId}`;
        }
        if (!condition.teamId && condition.isTie) {
          teamKey = "DRAW";
        }
        if (!state.hashCode[gameId]) {
          state.hashCode[gameId] = {};
        }
        state.hashCode[gameId][teamKey] = hashCode;

        if (!state.odds[gameId]) {
          state.odds[gameId] = {};
        }
        if (condition.playerId) {
          teamKey = `p${condition.playerId}${condition.overUnder || (condition.betValue < 0 ? "UNDER" : "OVER")}`;
        }
        if (!state.odds[gameId][teamKey]) {
          state.odds[gameId][teamKey] = {};
        }
        listings.forEach((listing) => {
          const siteKey = listing.site.id;
          if (!state.odds[gameId][teamKey][siteKey]) {
            state.odds[gameId][teamKey][siteKey] = {};
          }
          const betValueKey = `${condition.betValue ?? "DEFAULT"}`;
          if (state.odds[gameId][teamKey][siteKey][betValueKey] !== listing.americanOdds) {
            state.odds[gameId][teamKey][siteKey][betValueKey] = listing.americanOdds;
          }

          const sportbook = state.allSportbooks.find((book) => book.id === listing.site.id);
          if (sportbook) {
            const sisterIds = sportbook.sisterSiteIds ?? [];
            sisterIds.forEach((sisterId) => {
              if (!state.odds[gameId][teamKey][sisterId]) {
                state.odds[gameId][teamKey][sisterId] = {};
              }
              if (state.odds[gameId][teamKey][sisterId][betValueKey] !== listing.americanOdds) {
                state.odds[gameId][teamKey][sisterId][betValueKey] = listing.americanOdds;
              }
            });
          }

          if (state.selectedSportbooks.find((site) => site.id === listing.site.id)) {
            if (!state.betValueRange[betKey]) {
              state.betValueRange[betKey] = [betValue];
            } else if (!state.betValueRange[betKey].includes(betValue)) {
              state.betValueRange[betKey].push(betValue);
            }
          }
          if (!state.odds[gameId]) {
            state.odds[gameId] = {};
          }
          const game = state.gameData[gameId];
          if (game) {
            const sumValue = !shouldInvert && game.homeTeam.id === condition.teamId ? betValue * -1 : betValue;
            if (!sums[betKey]) {
              sums[betKey] = {};
              counts[betKey] = {};
            }
            if (!sums[betKey][sumValue]) {
              sums[betKey][sumValue] = americanToDecimal(listing.americanOdds);
              counts[betKey][sumValue] = 1;
            } else {
              sums[betKey][sumValue] += americanToDecimal(listing.americanOdds);
              counts[betKey][sumValue] += 1;
            }
          }
          // end adding bet value range
        });
      });

      Object.keys(sums).forEach((betId) => {
        let bet = 0;
        let maxCount = 0;
        let offset = Infinity;
        let closest = 0;
        const sum = sums[betId];
        const count = counts[betId];

        Object.keys(sum).forEach((betValue) => {
          const avg = sum[+betValue] / count[+betValue];
          if (avg >= -1.77 && avg <= 2.3) {
            if (count[+betValue] >= maxCount) {
              bet = +betValue;
              maxCount = count[+betValue];
            }
          }
          if (Math.abs(avg - 2) < offset) {
            closest = +betValue;
            offset = Math.abs(avg - 2);
          }
        });
        if (bet) {
          if (!state.selectedBetValue[betId]) {
            state.selectedBetValue[betId] = bet;
          }
        } else if (closest && !state.selectedBetValue[betId]) {
          state.selectedBetValue[betId] = closest;
        }
      });
    },
    setGamesData: (state, action: PayloadAction<GameData[]>) => {
      const data = action.payload;
      data.forEach((game) => {
        state.gameData[game.id] = game;
      });
    },
    setPlayerData: (state, action: PayloadAction<IPlayerType[]>) => {
      const data = action.payload;
      data.forEach((player) => {
        state.playerData[player.id] = player;
      });
    },
    setSelectedBetValue(state, action: PayloadAction<SetSpreadParams>) {
      const { gameId, betValue } = action.payload;
      state.selectedBetValue[gameId] = betValue;
    },
    setSelectedSportbooks(state, action: PayloadAction<BetMarketSiteBetSiteType[]>) {
      state.selectedSportbooks = action.payload;
    },
    setAllSportbooks(state, action: PayloadAction<BetMarketSiteBetSiteType[]>) {
      state.allSportbooks = action.payload;
    },
    setPlayerByGames(state, action: PayloadAction<Record<string, number[]>>) {
      state.playerByGames = action.payload;
    },
    setTeamByGames(state, action: PayloadAction<Record<string, number[]>>) {
      state.teamByGames = action.payload;
    },
    resetGamesData: (state) => {
      state.gameData = {};
    },
    resetOddsData: (state) => {
      state.odds = {};
      state.betValueRange = {};
      state.selectedBetValue = {};
      state.draws = {};
      state.hashCode = {};
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setGraphOpen: (state, action: PayloadAction<boolean>) => {
      state.isGraphOpen = action.payload;
    },
    setGraphData: (state, action: PayloadAction<GraphTeamData | undefined>) => {
      state.graphTeamData = action.payload;
    },
  },
});

export const betCacheActions = betCache.actions;

export default betCache.reducer;
