import { styled, Modal, Box, Typography } from "@mui/material";
import React, { ComponentProps, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.customBackground.cards};
    border: 1px solid ${theme.palette.border.primary};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(2)};
  `,
);

type SucessPopupProps = {
  title?: React.ReactNode;
  message?: React.ReactNode;
  onClose?: () => void;
} & ComponentProps<typeof StyledBox>;

function SucessPopup({ title, message, onClose: onCloseProps, ...restProps }: SucessPopupProps) {
  const [sucessPopup, setSucessPopup] = useState<boolean>(true);
  const onModalClose = () => {
    setSucessPopup(false);
    onCloseProps?.();
  };

  return (
    <StyledModal open={Boolean(sucessPopup)} onClose={onModalClose}>
      <StyledBox alignItems="center" height="max-content" margin="auto" pb={2} px={2} width={455} {...restProps}>
        <CheckCircleIcon sx={{ width: 32, height: 32 }} color="success" />
        <Typography variant="header" textAlign="left" my={2}>
          {title || "Sucess!"}
        </Typography>
        {message && (
          <Typography variant="body1" color="text.secondary" textAlign="left" my={2}>
            {message}
          </Typography>
        )}
      </StyledBox>
    </StyledModal>
  );
}

export default SucessPopup;
