import { useUser } from "@/user/UserContext";
import { gql, useLazyQuery } from "@apollo/client";
import { useRouter } from "@/navigation";

export const FETCH_USER_PAYMENT_PORTAL = gql`
  query FetchUserPaymentPortal {
    user {
      paymentPortal
    }
  }
`;

export type FetchUserPaymentPortal = {
  user: {
    paymentPortal: string;
  };
};

function useFetchUserPaymentPortal(setError?: (val: Error | null) => void) {
  const user = useUser();
  const router = useRouter();

  const [fetchUserPaymentPortal, { loading }] = useLazyQuery<FetchUserPaymentPortal>(FETCH_USER_PAYMENT_PORTAL);
  const redirectUserToPaymentPortal = () => {
    if (!user.isLoggedIn) return;
    fetchUserPaymentPortal()
      .then((resData) => {
        const paymentPortal = resData.data?.user.paymentPortal;
        if (paymentPortal) {
          router.push(paymentPortal);
          setError?.(null);
        } else {
          setError?.(new Error("Invalid payment portal url"));
        }
      })
      .catch((err) => {
        setError?.(err);
      });
  };
  return { redirectUserToPaymentPortal, loading };
}

export default useFetchUserPaymentPortal;
