import React, { ComponentProps } from "react";
import { Box } from "@mui/material";
import { useTranslations } from "next-intl";
import LogoProgressSpinner from "../LogoProgressSpinner";

type PageSpinnerProps = {
  text?: string;
} & ComponentProps<typeof Box>;

function PageSpinner({ text, ...restProps }: PageSpinnerProps) {
  const t = useTranslations("words");
  return (
    <Box justifyContent="center" alignItems="center" display="flex" height="50vh" {...restProps}>
      <LogoProgressSpinner variant="indeterminate" />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{text || t("searching")}
    </Box>
  );
}

export default PageSpinner;
