import { addMinutes, subMinutes } from "date-fns";

export function convertToLocalUTCTime(date?: Date | null) {
  if (!date) return null;
  return addMinutes(date, date.getTimezoneOffset());
}

export function convertUTCToLocalTime(date?: Date | string | number | null) {
  if (!date) return null;
  if (typeof date === "string") {
    let dateString = date;
    if (date.charAt(date.length - 1) !== "Z") {
      dateString += "Z";
    }
    return new Date(dateString);
  }
  if (typeof date === "number") {
    const newDate = new Date(date * 1000);
    return newDate;
  }
  return subMinutes(date, date.getTimezoneOffset());
}

export function convertToApiDateUTCFormat(dateTime: number | undefined) {
  if (!dateTime) {
    return undefined;
  }
  const newDateTime = convertToLocalUTCTime(new Date(dateTime));
  if (!newDateTime) {
    return null;
  }
  return Math.round(newDateTime.getTime() / 1000);
}

export function convertToApiDate(dateTime: number | undefined) {
  if (!dateTime) {
    return undefined;
  }
  if (typeof dateTime === "number") {
    return Math.round(dateTime / 1000);
  }
  const newDateTime = new Date(dateTime).getTime();
  return Math.round(newDateTime / 1000);
}
