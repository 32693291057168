import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useMutation } from "@apollo/client";
import formatGroupName from "@/utis/formatGroupName";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useAppSelector } from "@/store/store";
import isNil from "@/utis/isNil";
import { useUser } from "@/user/UserContext";
import { toolOrder, toolDisplayNames, ToolEnum } from "@/user/UserRestrictions/common";
import { PackageTypeEnum } from "@/user/common";
import { useRouter } from "@/navigation";
import { useFormatter, useTranslations } from "next-intl";
import useFetchUserPaymentPortal from "@/hooks/useFetchUserPaymentPortal";
import { GET_PAYMENT_URL, GetPaymentUrl, GetPaymentUrlVariables, getPackageName, AvailablePlansProps } from "../AvailablePlans/common";
import ErrorBoundaryPopup from "../ErrorBoundary/ErrorBoundaryPopup";
import StyledTypography from "../StyledComponents/EllipseTypography";


const StyledRow = styled(Stack)(
    ({ theme }) => `
`,
);

const StyledAccordian = styled(Accordion)`
  background-color: inherit;
  background-image: unset;
  box-shadow: unset;

  &.Mui-expanded::before {
    opacity: 1 !important;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
}));

const StyledColumn = styled(Stack)(
    ({ theme }) => `
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: end;
    overflow: hidden;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(1)};
    line-height: 1;
    &:first-of-type {
        max-width: 200px;
        min-width: 50px;
        align-items: start;
        justify-content: center;
    }
    ${theme.breakpoints.down("md")} {
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
    }
    &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
        padding: ${theme.spacing(1)};
        background-color: ${theme.palette.customBackground.cards};
    }
    & .MuiTypography-root {
        line-height: 1;
    }
`,
);

const StyledButton = styled(Button, {
    shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
    ({ theme, textColor }) => `
    height: 36px;
    width: fit-content;
    font-size: ${theme.typography.body2.fontSize};
    font-weight: bold;
    padding: ${theme.spacing(1.5)};
    ${theme.breakpoints.down("md")} {
        padding: ${theme.spacing(0.5)};
    }
    &.Mui-disabled {
        color: ${textColor || theme.palette.text.primary};
    }
`,
);

const getRestrictionsLimitText = (limit: number | undefined | null, per?: boolean) => {
    if (isNil(limit)) return <Typography variant="body2">-</Typography>;
    return (
        <Typography variant="body2">
            {limit}
            {per && "%"}
        </Typography>
    );
};


function AvailablePlansModal({ ...restProps }: AvailablePlansProps) {
    const user = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const constants = useAppSelector((state) => state.constantsReducer);

    const router = useRouter();
    const restrictions = constants?.restrictions?.filter((res) => res.package !== "LOGGED_IN");

    const [fetchPaymentUrl, { loading: fetchPaymentUrlLoading }] = useMutation<GetPaymentUrl, GetPaymentUrlVariables>(
        GET_PAYMENT_URL,
    );

    const userHasNoActivePackage =
        isNil(user.data?.activePackageType) ||
        user.data?.activePackageType === PackageTypeEnum.ANONYMOUS ||
        user.data?.activePackageType === PackageTypeEnum.LOGGED_IN;

    const { loading: paymentPortalLoading } = useFetchUserPaymentPortal();

    const redirectUserToPayment = (packageType: PackageTypeEnum) => {
        if (userHasNoActivePackage) {
            fetchPaymentUrl({ variables: { packageType } })
                .then((resData) => {
                    const paymentUrl = resData.data?.user.purchase;
                    if (paymentUrl) {
                        router.push(paymentUrl);
                    }
                })
                .catch((err) => <ErrorBoundaryPopup message={err.message} />);
        }
    };

    const onPackageTypeClick = (packageType: PackageTypeEnum, isCancel?: boolean) => {
        if (packageType === PackageTypeEnum.ANONYMOUS) {
            window.location.reload();
        } else {
            redirectUserToPayment(packageType);
        }
    };

    const [expanded, setExpanded] = React.useState<string | false>();

    useEffect(() => {
        const currPackageIndedx = restrictions?.findIndex(
            (restriction) => (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package,
        );
        if (currPackageIndedx !== undefined && expanded === undefined) {
            setExpanded(`panel${currPackageIndedx}`);
        }
    }, [expanded, restrictions, setExpanded, user.data?.activePackageType]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const t = useTranslations("plans");

    const format = useFormatter();

    if (isMobile) {
        return (
            <Stack direction="column" position="relative" {...restProps}>
                <Stack justifyContent="center" pb={expanded ? undefined : 1}>
                    <StyledTypography variant="karantinaHeader" textAlign="center" fontSize={28}>
                        {t("Pick_Your_plan")}
                    </StyledTypography>
                </Stack>

                {restrictions?.map((restriction, i) => (
                    <StyledAccordian key={i} expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)}>
                        <StyledAccordionSummary
                            aria-controls={`panel${i}d-content`}
                            id={`panel${i}d-header`}
                            expandIcon={<ArrowDropDownIcon />}
                        >
                            <Stack direction="column" marginRight="auto">
                                <StyledTypography
                                    color="text.primary"
                                    variant="header"
                                    fontWeight="bold"
                                    sx={{ width: "100%" }}
                                    textAlign="center"
                                >
                                    {getPackageName(restriction.package)}
                                </StyledTypography>
                                <Typography variant="label" fontWeight="bold" color="text.primary">
                                    ${format.number(restriction.price ?? 0, { currency: "USD", maximumFractionDigits: 0 })}/
                                    {isMobile ? "mo" : "Month"}
                                </Typography>
                            </Stack>
                            <StyledButton
                                fullWidth={!isMobile}
                                variant="contained"
                                color="secondary"
                                size="small"
                                disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                                onClick={() => onPackageTypeClick(restriction.package)}
                            >
                                {t("choose_plan")}
                            </StyledButton>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                            {toolOrder.map((tool, ind) => {
                                if (tool === ToolEnum.FREE_BET_CONVERTER) return null;
                                if (tool === ToolEnum.ODDS_SCREEN) return (
                                    <StyledRow direction="row" key={ind}>
                                        <StyledColumn flexDirection="column">
                                            <Typography fontWeight="bold" variant="header">
                                                {toolDisplayNames[tool]}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("refresh_time")}
                                            </Typography>
                                        </StyledColumn>
                                        <StyledColumn flexDirection="column" justifyContent="center" textAlign="center">
                                            {i === 0 ? (
                                                <Typography variant="body2" color="text.primary">
                                                    {t("15_Second_Refresh")}
                                                </Typography>
                                            ) : (
                                                <Typography variant="body2" color="text.primary">
                                                    {t("2_4_Second_Refresh")}
                                                </Typography>
                                            )}
                                        </StyledColumn>
                                    </StyledRow>
                                );
                                const currRestriction = restriction.restrictions.find((res) => res.toolEnum === tool);
                                return (
                                    <StyledRow direction="row" key={ind}>
                                        <StyledColumn flexDirection="column">
                                            <Typography fontWeight="bold" variant="header">
                                                {formatGroupName(ToolEnum[tool])}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("roi_limit")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("max_results")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("sportsbook_limit")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("leagues_limit")}
                                            </Typography>
                                        </StyledColumn>
                                        <StyledColumn direction="column">
                                            {getRestrictionsLimitText(currRestriction?.rOILimit, true)}
                                            {getRestrictionsLimitText(currRestriction?.maxResult)}
                                            {getRestrictionsLimitText(currRestriction?.sportbookLimit)}
                                            {getRestrictionsLimitText(currRestriction?.leagueLimit)}
                                        </StyledColumn>
                                    </StyledRow>
                                );
                            })}
                            <StyledRow direction="row">
                                <StyledColumn flexDirection="column">
                                    <Typography fontWeight="bold" variant="header">
                                        {t("free_bet_converter")}
                                    </Typography>
                                </StyledColumn>
                                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                                    <CheckIcon color="success" fontSize="small" />
                                </StyledColumn>
                            </StyledRow>
                            <StyledRow direction="row">
                                <StyledColumn flexDirection="column">
                                    <Typography fontWeight="bold" variant="header">
                                        {t("pre_live_odds")}
                                    </Typography>
                                </StyledColumn>
                                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                                    <CheckIcon color="success" fontSize="small" />
                                </StyledColumn>
                            </StyledRow>
                            <StyledRow direction="row">
                                <StyledColumn flexDirection="column">
                                    <Typography fontWeight="bold" variant="header">
                                        {t("portable_filters")}
                                    </Typography>
                                </StyledColumn>
                                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                                    <CheckIcon color="success" fontSize="small" />
                                </StyledColumn>
                            </StyledRow>
                            <StyledRow direction="row">
                                <StyledColumn flexDirection="column">
                                    <Typography fontWeight="bold" variant="header">
                                        {t("device_limit")}
                                    </Typography>
                                </StyledColumn>
                                <StyledColumn direction="column" style={{ justifyContent: "center" }}>
                                    <Typography variant="body2">1</Typography>
                                </StyledColumn>
                            </StyledRow>
                            <StyledRow direction="row">
                                <StyledColumn flexDirection="column">
                                    <Typography fontWeight="bold" variant="header">
                                        {t("trial_included")}
                                    </Typography>
                                </StyledColumn>
                                <StyledColumn direction="column" style={{ justifyContent: "center" }}>
                                    <Typography variant="body2">
                                        {isNil(restriction.trialDays) || restriction.price === null
                                            ? "-"
                                            : t("n_days", { value: restriction.trialDays })}
                                    </Typography>
                                </StyledColumn>
                            </StyledRow>
                        </AccordionDetails>
                    </StyledAccordian>
                )
                )}
            </Stack>
        );
    }

    return (
        <Stack direction="column" position="relative" {...restProps}>
            <Stack justifyContent="center" pb={2}>
                <StyledTypography variant="karantinaHeader" textAlign="center" fontSize={28}>
                    {t("Pick_Your_plan")}
                </StyledTypography>
            </Stack>

            <Stack direction="row">
                <StyledColumn maxWidth={120} />
                {restrictions?.map((restriction, i) => (
                    <StyledColumn key={i} direction="column">
                        <StyledTypography
                            color="text.primary"
                            variant="header"
                            fontWeight="bold"
                            sx={{ width: "100%" }}
                            textAlign="center"
                        >
                            {getPackageName(restriction.package)}
                        </StyledTypography>
                        <Typography variant="body1Bold" my={1} color="text.primary">
                            ${format.number(restriction.price ?? 0, { currency: "USD", maximumFractionDigits: 0 })}/{isMobile ? "mo" : "Month"}
                        </Typography>
                        <StyledButton
                            fullWidth={!isMobile}
                            variant="contained"
                            color="secondary"
                            size="small"
                            disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                            onClick={() => onPackageTypeClick(restriction.package)}
                        >
                            {t("choose_plan")}
                        </StyledButton>
                    </StyledColumn>
                )
                )}
            </Stack>
            {toolOrder.map((tool, i) => {
                if (tool === ToolEnum.FREE_BET_CONVERTER) return null;
                if (tool === ToolEnum.ODDS_SCREEN) return (
                    <StyledRow direction="row" key={i}>
                        <StyledColumn flexDirection="column">
                            <Typography fontWeight="bold" variant="header">
                                {toolDisplayNames[tool]}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {t("refresh_time")}
                            </Typography>
                        </StyledColumn>
                        <StyledColumn flexDirection="column">
                            <Typography variant="body2" color="text.primary">
                                {t("15_Second_Refresh")}
                            </Typography>
                        </StyledColumn>
                        <StyledColumn flexDirection="column">
                            <Typography variant="body2" color="text.primary">
                                {t("2_4_Second_Refresh")}
                            </Typography>
                        </StyledColumn>
                        <StyledColumn flexDirection="column">
                            <Typography variant="body2" color="text.primary">
                                {t("2_4_Second_Refresh")}
                            </Typography>
                        </StyledColumn>
                        <StyledColumn flexDirection="column">
                            <Typography variant="body2" color="text.primary">
                                {t("2_4_Second_Refresh")}
                            </Typography>
                        </StyledColumn>
                    </StyledRow>
                );
                return (
                    <StyledRow direction="row" key={i}>
                        <StyledColumn flexDirection="column">
                            <Typography fontWeight="bold" variant="header">
                                {toolDisplayNames[tool]}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {t("roi_limit")}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {t("max_results")}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {t("sportsbook_limit")}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {t("leagues_limit")}
                            </Typography>
                        </StyledColumn>
                        {restrictions?.map((restriction, ind) => {
                            const currRestriction = restriction.restrictions.find((res) => res.toolEnum === tool);
                            return (
                                <StyledColumn direction="column" key={ind}>
                                    {getRestrictionsLimitText(currRestriction?.rOILimit, true)}
                                    {getRestrictionsLimitText(currRestriction?.maxResult)}
                                    {getRestrictionsLimitText(currRestriction?.sportbookLimit)}
                                    {getRestrictionsLimitText(currRestriction?.leagueLimit)}
                                </StyledColumn>
                            );
                        })}
                    </StyledRow>
                );
            })}
            <StyledRow direction="row">
                <StyledColumn flexDirection="column">
                    <Typography fontWeight="bold" variant="header">
                        {t("free_bet_converter")}
                    </Typography>
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
            </StyledRow>
            <StyledRow direction="row">
                <StyledColumn flexDirection="column">
                    <Typography fontWeight="bold" variant="header">
                        {t("pre_live_odds")}
                    </Typography>
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
            </StyledRow>
            <StyledRow direction="row">
                <StyledColumn flexDirection="column">
                    <Typography fontWeight="bold" variant="header">
                        {t("portable_filters")}
                    </Typography>
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
                <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
                    <CheckIcon color="success" fontSize="small" />
                </StyledColumn>
            </StyledRow>
            <StyledRow direction="row">
                <StyledColumn flexDirection="column">
                    <Typography fontWeight="bold" variant="header">
                        {t("device_limit")}
                    </Typography>
                </StyledColumn>
                <StyledColumn direction="column" style={{ justifyContent: "center" }}>
                    <Typography variant="body2">1</Typography>
                </StyledColumn>
                <StyledColumn direction="column" style={{ justifyContent: "center" }}>
                    <Typography variant="body2">1</Typography>
                </StyledColumn>
                <StyledColumn direction="column" style={{ justifyContent: "center" }}>
                    <Typography variant="body2">1</Typography>
                </StyledColumn>
                <StyledColumn direction="column" style={{ justifyContent: "center" }}>
                    <Typography variant="body2">1</Typography>
                </StyledColumn>
            </StyledRow>
            <StyledRow direction="row">
                <StyledColumn flexDirection="column">
                    <Typography fontWeight="bold" variant="header">
                        {t("trial_included")}
                    </Typography>
                </StyledColumn>
                {restrictions?.map((restriction, i) => (
                    <StyledColumn direction="column" style={{ justifyContent: "center" }} key={i}>
                        <Typography variant="body2">
                            {isNil(restriction.trialDays) || restriction.price === null ? "-" : t("n_days", { value: restriction.trialDays })}
                        </Typography>
                    </StyledColumn>
                ))}
            </StyledRow>
        </Stack>
    );
}

export default AvailablePlansModal;