export const stringContainsPattern = (inputString: string, patterns: string[]): string => {
  // eslint-disable-next-line no-restricted-syntax
  for (const pattern of patterns) {
    if (inputString.includes(pattern)) {
      return pattern;
    }
  }
  return "";
};

export const stringStartsWithPattern = (inputString: string, patterns: string[]): string => {
  // eslint-disable-next-line no-restricted-syntax
  for (const pattern of patterns) {
    if (inputString.startsWith(pattern)) {
      return pattern;
    }
  }
  return "";
};
