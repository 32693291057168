/* eslint-disable no-param-reassign */
import { TeamType } from "@/components/Common/Fragments/ToolsSubscriptionFragment";
import { IPlayerType } from "@/components/Odds/common";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// TODO: Make this global cache some sort of

export type GameWeatherType = {
  time: number | null;
  tempF: number;
  condition: string;
  windMPH: number;
  windDirection: string;
  feelsLikeTempF: number;
  chanceOfRain: number;
  precipInches: number;
};

export type PlayerType = {
  id: string;
  firstName: string;
  lastName: string;
};

export type GameData = {
  id: string;
  homeTeam: TeamType;
  awayTeam: TeamType;
  startDateTime: number;
  weather?: GameWeatherType[];
};

export type GameDataCacheType = {
  gameData: Partial<Record<string, GameData>>;
  playerData: Partial<Record<number, IPlayerType>>;
};

const initialState: GameDataCacheType = {
  gameData: {},
  playerData: {},
};

export const gameDataCache = createSlice({
  name: "gameDataCache",
  initialState,
  reducers: {
    setGamesData: (state, action: PayloadAction<GameData[]>) => {
      const data = action.payload;
      data.forEach((game) => {
        state.gameData[game.id] = game;
      });
    },
    setPlayerData: (state, action: PayloadAction<IPlayerType[]>) => {
      const data = action.payload;
      data.forEach((player) => {
        state.playerData[player.id] = player;
      });
    },
    clearCacheData: (state) => {
      state.gameData = {};
      state.playerData = {};
    },
  },
});

export const gameDataCacheAction = gameDataCache.actions;

export default gameDataCache.reducer;
