export enum BetMarketTypeTimeEnum {
  GAME = "GAME",
  NO_OT = "NO_OT",
  TO_QUALIFY = "TO_QUALIFY",
  FIRST_HALF = "FIRST_HALF",
  SECOND_HALF = "SECOND_HALF",
  FIRST_QUARTER = "FIRST_QUARTER",
  SECOND_QUARTER = "SECOND_QUARTER",
  THIRD_QUARTER = "THIRD_QUARTER",
  FOURTH_QUARTER = "FOURTH_QUARTER",
  FIRST_INNING = "FIRST_INNING",
  SECOND_INNING = "SECOND_INNING",
  THIRD_INNING = "THIRD_INNING",
  FOURTH_INNING = "FOURTH_INNING",
  FIFTH_INNING = "FIFTH_INNING",
  SIXTH_INNING = "SIXTH_INNING",
  SEVENTH_INNING = "SEVENTH_INNING",
  EIGHTH_INNING = "EIGHTH_INNING",
  NINTH_INNING = "NINTH_INNING",
  TENTH_INNING = "TENTH_INNING",
  FIRST_SET = "FIRST_SET",
  SECOND_SET = "SECOND_SET",
  THIRD_SET = "THIRD_SET",
  FOURTH_SET = "FOURTH_SET",
  FIFTH_SET = "FIFTH_SET",
}

export const betMarketTypeMenuLabels: Record<BetMarketTypeTimeEnum, { label: string; order: number }> = {
  [BetMarketTypeTimeEnum.GAME]: { label: "Game", order: 0 },
  [BetMarketTypeTimeEnum.NO_OT]: { label: "NoOT", order: 22 },
  [BetMarketTypeTimeEnum.TO_QUALIFY]: { label: "To Qualify", order: 23 },
  [BetMarketTypeTimeEnum.FIRST_HALF]: { label: "1st Half", order: 1 },
  [BetMarketTypeTimeEnum.SECOND_HALF]: { label: "2nd Half", order: 2 },
  [BetMarketTypeTimeEnum.FIRST_QUARTER]: { label: "1st Quarter", order: 3 },
  [BetMarketTypeTimeEnum.SECOND_QUARTER]: { label: "2nd Quarter", order: 4 },
  [BetMarketTypeTimeEnum.THIRD_QUARTER]: { label: "3rd Quarter", order: 5 },
  [BetMarketTypeTimeEnum.FOURTH_QUARTER]: { label: "4th Quarter", order: 6 },
  [BetMarketTypeTimeEnum.FIRST_INNING]: { label: "1st Inning", order: 7 },
  [BetMarketTypeTimeEnum.SECOND_INNING]: { label: "2nd Inning", order: 8 },
  [BetMarketTypeTimeEnum.THIRD_INNING]: { label: "3rd Inning", order: 9 },
  [BetMarketTypeTimeEnum.FOURTH_INNING]: { label: "4th Inning", order: 10 },
  [BetMarketTypeTimeEnum.FIFTH_INNING]: { label: "5th Inning", order: 11 },
  [BetMarketTypeTimeEnum.SIXTH_INNING]: { label: "6th Inning", order: 12 },
  [BetMarketTypeTimeEnum.SEVENTH_INNING]: { label: "7th Inning", order: 13 },
  [BetMarketTypeTimeEnum.EIGHTH_INNING]: { label: "8th Inning", order: 14 },
  [BetMarketTypeTimeEnum.NINTH_INNING]: { label: "9th Inning", order: 15 },
  [BetMarketTypeTimeEnum.TENTH_INNING]: { label: "9th Inning", order: 16 },
  [BetMarketTypeTimeEnum.FIRST_SET]: { label: "1st Set", order: 17 },
  [BetMarketTypeTimeEnum.SECOND_SET]: { label: "2nd Set", order: 18 },
  [BetMarketTypeTimeEnum.THIRD_SET]: { label: "3rd Set", order: 19 },
  [BetMarketTypeTimeEnum.FOURTH_SET]: { label: "4th Set", order: 20 },
  [BetMarketTypeTimeEnum.FIFTH_SET]: { label: "5th Set", order: 21 },
};
