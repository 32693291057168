import { gql, useQuery } from "@apollo/client";
import { ConstantsType, constantsAction, sortSportsBookWithId } from "@/store/constants";
import { useAppSelector, useAppDispatch } from "@/store/store";
import { FETCH_ALL_AVAILABLE_PLANS_FRAGMENT } from "@/components/AvailablePlans/common";
import { BET_MARKET_SITE_FRAGMENT } from "@/components/Common/Fragments/BetMarketSiteBetSiteTypeFragment";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";

export const GET_CONSTANTS = gql`
  query GetConstants {
    constants {
      betGroups {
        value
        order
      }
      betMarketInfo {
        displayName
        value
        groupValue
        order
        time
        templateString
      }
      leagues {
        id
        sportId
        isActive
      }
      betSites {
        isSisterSite
        sisterSiteIds
        enumValue
        active
        ratings
        ...BetMarketSiteBetSiteTypeFragment
      }
      ...FetchAllAvailablePlansConstantsTypeFragment
    }
  }
  ${FETCH_ALL_AVAILABLE_PLANS_FRAGMENT}
  ${BET_MARKET_SITE_FRAGMENT}
`;

export type GetConstants = {
  constants: ConstantsType;
};

function getDataToReturn(data: GetConstants | undefined, sportsBookSortId: number[] | undefined) {
  if (!data) return undefined;
  return { ...data?.constants, betSites: sortSportsBookWithId(sportsBookSortId, data.constants.betSites) };
}

export default function useGetConstants() {
  const constants = useAppSelector((state) => state.constantsReducer);
  const sportsBookSortId = useUserSettingsContextSelector((ctx) => ctx.globalSettings.sportsBookSortId);

  const dispatch = useAppDispatch();
  const { data, loading } = useQuery<GetConstants>(GET_CONSTANTS, {
    // always check for latest constant to present
    skip: constants.betSites?.[0]?.ratings !== undefined,
    onCompleted: (resData) => {
      if (resData?.constants) {
        dispatch(constantsAction.setConstants({ ...resData.constants, sportsBookSortId }));
      }
    },
  });

  return {
    // always check for latest constant to present
    data: constants.betSites?.[0]?.ratings !== undefined ? constants : getDataToReturn(data, sportsBookSortId),
    loading,
  };
}
