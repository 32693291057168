import React from "react";
import { SelectMenuVariant } from "./constant";

export type SelectMenuContextValue = {
  menuState: {
    anchorEl: Element | null;
    onOpen: (ev: React.MouseEvent<Element, MouseEvent>) => void;
    onClose: () => void;
  };
  variant: SelectMenuVariant;
  titleInfo?: React.ReactNode;
  disabled?: boolean;
};

export default React.createContext<SelectMenuContextValue>(undefined!);
