export function americanToDecimal(americanOdds: number) {
  if (americanOdds > 0) {
    return americanOdds / 100 + 1;
  }
  return 100 / Math.abs(americanOdds) + 1;
}

function gcd(a: number, b: number) {
  if (b === 0) {
    return a;
  }
  return gcd(b, a % b);
}

export function americanToFractional(americanOdds: number) {
  let numerator;
  let denominator;

  if (americanOdds > 0) {
    numerator = americanOdds;
    denominator = 100;
  } else {
    numerator = 100;
    denominator = Math.abs(americanOdds);
  }

  const divisor = gcd(numerator, denominator);
  numerator /= divisor;
  denominator /= divisor;

  return [numerator, denominator];
}

export function americanToPercentage(americanOdds: number) {
  if (americanOdds > 0) return 100 / (americanOdds + 100.0);
  return (-1 * americanOdds) / (-1 * americanOdds + 100.0);
}

export function decimalOddsToPercentage(decimalOdds: number) {
  if (decimalOdds <= 1) {
    throw new Error("Decimal odds must be greater than 1");
  }

  const impliedProbability = 1 / decimalOdds;
  return impliedProbability; // convert to percentage
}

export function decimalToAmerican(decimalOdds: number) {
  if (decimalOdds > 2) {
    return Math.round((decimalOdds - 1) * 100);
  }
  if (decimalOdds < 2) {
    return Math.round(-100 / (decimalOdds - 1));
  }
  // Decimal odds of 2.00 are an even bet in American odds
  return 100;
}

export function americanToImpliedOdds(americanOdds: number) {
  if (americanOdds > 0) {
    return 100 / (americanOdds + 100);
  }
  return Math.abs(americanOdds) / (Math.abs(americanOdds) + 100);
}

export function percentageToAmerican(percentage: number) {
  if (percentage <= 0) return +20000;
  if (percentage >= 1) return -20000;

  const decimalOdds = 1 / percentage;

  if (decimalOdds >= 2) {
    return Math.round((decimalOdds - 1) * 100);
  }
  return Math.round(-100 / (decimalOdds - 1));
}

export function calculateKellyBet(
  decimalOdds: number,
  probabilityOfWinning: number,
  bankroll: number,
  kellyFraction: number = 1,
) {
  if (kellyFraction < 0 || kellyFraction > 1) {
    throw new Error("Kelly fraction must be between 0 and 1");
  }

  const betPercentage =
    (((decimalOdds - 1) * probabilityOfWinning - (1 - probabilityOfWinning)) / (decimalOdds - 1)) * kellyFraction;

  return bankroll * betPercentage;
}
