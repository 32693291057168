import React, { ComponentProps, useContext } from "react";
import { Button, Typography, styled } from "@mui/material";

import { useTranslations } from "next-intl";
import SelectMenuContext from "./SelectMenuContext";
import { SelectMenuVariant } from "./constant";

export const ApplyButtonLong = styled(Button)`
  text-transform: none;
  width: 100%;
  font-weight: bold;
`;

export const ApplyButtonShort = styled(Button)(
  ({ theme }) => `
    padding: 0;
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    background-color: ${theme.palette.button.common};
    color: ${theme.palette.button.activeText};
    text-transform: none;
    &:hover {
      background-color: ${theme.palette.button.common};
      color: ${theme.palette.button.activeText};
    }
    
    ${theme.breakpoints.down("md")} {
      border-radius: ${theme.spacing(3)};
    }
  `,
);

export enum ApplyButtonVariants {
  LONG = "LONG",
  SHORT = "SHORT",
}

type SelectMenuSelectAllProps = {
  applyBtnVariant?: ApplyButtonVariants;
} & ComponentProps<typeof Button>;

export default function SelectMenuApply(props: SelectMenuSelectAllProps) {
  const { onClick: onClickProp, applyBtnVariant = ApplyButtonVariants.LONG, children, ...restProps } = props;
  const t = useTranslations("filters");

  const { menuState, variant, disabled } = useContext(SelectMenuContext);
  const handleClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (onClickProp) onClickProp(ev);
    if (!ev.defaultPrevented) menuState.onClose();
  };

  if (variant === SelectMenuVariant.BOX) return null;
  const ApplyButton = applyBtnVariant === ApplyButtonVariants.LONG ? ApplyButtonLong : ApplyButtonShort;

  return (
    <ApplyButton
      variant="contained"
      sx={{ borderRadius: 0, minHeight: 50 }}
      onClick={handleClick}
      disabled={disabled}
      {...restProps}
    >
      {children || <Typography variant="body1Bold">{t("apply_filters")}</Typography>}
    </ApplyButton>
  );
}
