import { gql } from "@apollo/client";
import CryptoJS from "crypto-js";

export enum LoginType {
  SIGN_UP = "SIGN_UP",
  LOG_IN = "LOG_IN",
}

export const USER_SIGNUP = gql`
  mutation UserSignup($email: String!, $cfTurnstileToken: String!, $hashCode: String!, $promoCode: String) {
    user {
      signup(email: $email, cfTurnstileToken: $cfTurnstileToken, hashCode: $hashCode, promoCode: $promoCode)
    }
  }
`;

export type UserSignup = {
  user: {
    signup: boolean;
  };
};

export type UserSignupVariables = {
  email: string;
  cfTurnstileToken: string;
  hashCode: string;
  promoCode?: string;
};

export function generateHashKey() {
  // Generate a random string to hash
  const randomString = Math.random().toString(36).substring(2); // Using a random string as an example

  // Create a SHA-256 hash of the random string
  const hash = CryptoJS.SHA256(randomString).toString(CryptoJS.enc.Hex);

  // Return the first 20 characters of the hash key
  return hash.substring(0, 20);
}
