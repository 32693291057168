export const BET_MARKET_SITE_FRAGMENT = `
  fragment BetMarketSiteBetSiteTypeFragment on BetSiteType {
    id
    fullImage
    name
    abbreviation
    iconImage
  }
`;

export type BetMarketSiteBetSiteTypeFragment = {
  id: number;
  fullImage: string;
  name: string;
  abbreviation: string;
  iconImage: string;
};
