import * as React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function CloseRoundedIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={23} height={24} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M22.663 2.05q0.162 -0.17 0.25 -0.39a1.246 1.246 0 0 0 -0.25 -1.308 1.15 1.15 0 0 0 -0.373 -0.26 1.112 1.112 0 0 0 -1.254 0.26L11.5 10.304 1.964 0.352A1.131 1.131 0 0 0 1.151 0C0.845 0 0.553 0.126 0.337 0.352S0 0.882 0 1.2s0.121 0.623 0.337 0.849l9.536 9.951L0.337 21.95c-0.216 0.226 -0.337 0.531 -0.337 0.85s0.121 0.623 0.337 0.848c0.216 0.226 0.508 0.352 0.814 0.352s0.597 -0.127 0.813 -0.352L11.5 13.696l9.536 9.952c0.216 0.226 0.508 0.352 0.813 0.352 0.306 0 0.598 -0.127 0.814 -0.352s0.337 -0.53 0.337 -0.849 -0.121 -0.623 -0.337 -0.849l-9.536 -9.949z" />
    </SvgIcon>
  );
}
export default CloseRoundedIcon;
