import { PickUserRestrictionType } from "@/user/UserRestrictions/common";
import { PackageTypeEnum } from "@/user/common";
import formatGroupName from "@/utis/formatGroupName";
import { gql } from "@apollo/client";
import { Stack } from "@mui/material";
import { ComponentProps } from "react";

export const GET_PAYMENT_URL = gql`
  mutation GetPaymentUrl($packageType: PackageTypeEnum!) {
    user {
      purchase(packageType: $packageType)
    }
  }
`;

export type GetPaymentUrl = {
  user: {
    purchase: string;
  };
};

export type GetPaymentUrlVariables = {
  packageType: PackageTypeEnum;
};

export const FETCH_ALL_AVAILABLE_PLANS_FRAGMENT = `
	fragment FetchAllAvailablePlansConstantsTypeFragment on ConstantsType {
		restrictions {
			package
			price
      trialDays
			restrictions {
				leagueLimit
				maxResult
				rOILimit
				sportbookLimit
				toolEnum
			}
		}
	}
`;
export type FetchAllAvailablePlansConstantsTypeFragment = {
  restrictions: Array<{
    package: PackageTypeEnum;
    price: number | null;
    trialDays: number | null;
    restrictions: PickUserRestrictionType[];
  }> | null;
};

export const FETCH_ALL_AVAILABLE_PLANS = gql`
  query GetAllAvailablePlans {
    constants {
      restrictions {
        package
        price
        trialDays
        restrictions {
          leagueLimit
          maxResult
          rOILimit
          sportbookLimit
          toolEnum
        }
      }
    }
  }
`;

export type GetAllAvailablePlans = {
  constants: {
    restrictions: Array<{
      package: PackageTypeEnum;
      price: number | null;
      trialDays: number | null;
      restrictions: PickUserRestrictionType[];
    }>;
  };
};

export function formatPackageName(packageType: PackageTypeEnum | string, isTrial?: boolean) {
  const packageName = formatGroupName(packageType);
  if (!isTrial) return packageName;
  return `Trial period for ${packageName}`;
}

export const getPackageName = (packageType: PackageTypeEnum | undefined) =>
  !packageType || packageType === PackageTypeEnum.ANONYMOUS || packageType === PackageTypeEnum.LOGGED_IN
    ? "Free"
    : formatPackageName(packageType);

export type AvailablePlansProps = {
  isTrial?: boolean;
  renewTime?: string;
  hideTopBtn?: boolean;
} & ComponentProps<typeof Stack>;
