import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { loadStateWithExpiry, setStateWithExpiry } from "@/utis/localStorage";
import { BetMarketSiteBetSiteTypeFragment } from "@/components/Common/Fragments/BetMarketSiteBetSiteTypeFragment";
import { BetSiteRatingEnum } from "@/components/Common/Enums/BetSiteRatingEnum";

const KEY = "SPORTS_BOOK";

export type BetMarketSiteBetSiteType = {
  isSisterSite: boolean;
  sisterSiteIds: number[] | null;
  enumValue: string;
  active: boolean;
  ratings: BetSiteRatingEnum[];
} & BetMarketSiteBetSiteTypeFragment;

const initialState: BetMarketSiteBetSiteType[] = loadStateWithExpiry(KEY) || [];

export const sportsBooks = createSlice({
  name: "sportsBooks",
  initialState,
  reducers: {
    setSportsBook: (state, action: PayloadAction<BetMarketSiteBetSiteType[]>) => {
      const newState = action.payload;
      setStateWithExpiry(KEY, newState);
      return newState;
    },
  },
});

export const sportsBooksAction = sportsBooks.actions;

export default sportsBooks.reducer;
