import { createSlice } from "@reduxjs/toolkit";
import { loadState, saveState } from "@/utis/localStorage";

const KEY = "LOCAL_DATA";

export type LocalStoreType = {
  alertConfirmed?: boolean;
};

const initialState: LocalStoreType = loadState(KEY) || {};

export const localStore = createSlice({
  name: "localStore",
  initialState,
  reducers: {
    setAlertConfirmed: (state) => {
      const newState = state;
      newState.alertConfirmed = true;
      saveState(KEY, newState);
      return newState;
    },
  },
});

export const localStoreAction = localStore.actions;

export default localStore.reducer;
